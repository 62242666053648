import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ClassicReportData } from "@planard/@core/entities/reports/classicReportData";
import { ScenarioService } from "@planard/@core/backend/service/scenario.service";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { EnumCurrency } from "@planard/@core/enums/EnumCurrency";
import { Products } from "@planard/@core/entities/product";
import { Scenario } from "@planard/@core/entities/scenario";
import { GuidanceService } from "@planard/@core/backend/service/guidance.service";
import { GuidancePlanningPeriod } from "@planard/@core/entities/guidancePlanningPeriod";
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { ReportScreenshotService } from "@planard/@core/backend/service/reportScreenshot.service";
import { ReportScreenshot } from "@planard/@core/entities/reportScreenshot";
import { FxRate } from "@planard/@core/entities/fxRate";
import { NarrativeRequest } from "@planard/@core/entities/narrativeRequest";
import { NarrativeService } from "@planard/@core/backend/service/narrative.service";
import { Department } from "@planard/@core/entities/department";
import { SubDepartment } from "@planard/@core/entities/subDepartment";
import { DepartmentService } from "@planard/@core/backend/service/department.service";
import { waterFallPlugin } from "chartjs-plugin-waterfall";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { AccountsService } from "@planard/@core/backend/service/accounts.service";
import { NumberDecimalPipe } from "@planard/@core/pipes/NumberDecimalPipe";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { Category } from "@planard/@core/entities/category";

@Component({
  templateUrl: "./cost-bridge.component.html",
  styles: [
    `
      .div {
        display: inline-block;
      }
    `,
  ],
  providers: [NumberDecimalPipe],
})
export class CostBridgeComponent implements OnInit {
  reportData: ClassicReportData = new ClassicReportData();
  barChartData: any[] = [];
  currencyList: EnumCurrency[] = EnumCurrency.values;
  productList: Products[];
  scenarioList: Scenario[];
  planningPeriodList: GuidancePlanningPeriod[];
  costCategoryList: Category[];
  categoryId: any = null;
  img = "";
  scenarioName: string;
  productName: string;
  currencyName: string;
  guidanceId: number;
  currency: any = 0;
  scenarioId: number;
  productId: number;
  period: number;
  reportScreenshot: ReportScreenshot = {};
  costRequestList: NarrativeRequest[];
  requestId: number;
  fxrateList: FxRate[];
  guidanceFxRateList: any[];
  isRun: boolean;
  guidanceIsStart: any = null;
  granularityList = [
    { value: 0, label: "Total Company" },
    { value: 1, label: "Department" },
    { value: 2, label: "Sub Department" },
  ];
  granularity: number = null;
  departmentList: Department[];
  subDepartmentList: SubDepartment[];
  departmentId: any;
  subDepartmentId: any;
  isRoleValid: boolean;
  pairCurrencyList = [];
  companyPackage: string;
  companyCurrency: string;
  companyNumberFormat: string;

  public barChartOptions: any = {};
  public barChartType = "bar";
  public barChartColors: Array<any> = [];

  waterFallPlugin: waterFallPlugin;

  isAdmin;
  isApprover;
  subDeptList: any[];

  numberFormat: string;
  decimalFormat: string;

  @ViewChild("screen", { static: true }) screen: any;

  requestControl = new FormControl("", [Validators.required]);
  categoryControl = new FormControl("", [Validators.required]);
  granularityControl = new FormControl("", [Validators.required]);
  depControl = new FormControl("", [Validators.required]);
  subControl = new FormControl("", [Validators.required]);
  currencyControl = new FormControl("", [Validators.required]);

  constructor(
    public toastr: ToastrService,
    public scenarioService: ScenarioService,
    public guidanceService: GuidanceService,
    public departmentService: DepartmentService,
    public narrativeService: NarrativeService,
    private captureService: NgxCaptureService,
    private numberDecimal: NumberDecimalPipe,
    private router: Router,
    public authService: AuthService,
    public costService: CostService,
    public accountsService: AccountsService,
    public translate: TranslateService,
    public reportScreenshotService: ReportScreenshotService,
    public productsService: ProductsService
  ) {
    this.numberFormat = this.authService.getNumberFormat();
    this.decimalFormat = this.authService.getDecimalFormat();

    this.companyPackage = this.authService.getCompanyPackage();

    if (this.companyPackage == "basic") this.isRoleValid = false;
    if (this.companyPackage == "premium")
      this.isRoleValid =
        this.authService.isAdmin() ||
        this.authService.isSupportFunctionApprover() ||
        this.authService.isSupportFunctionPlanner();

    this.isAdmin = this.authService.isAdmin();
    this.isApprover = this.authService.isApprover();

    this.departmentService
      .listAllSub /*planService.listApproverUsersSubDepartments*/
      ()
      .subscribe((result: any) => {
        this.subDeptList = result;
      });

    this.departmentService.listAll().subscribe((result) => {
      this.departmentList = result;
    });

    this.costService.listCategoriesCurrent().subscribe((result) => {
      this.costCategoryList = result;
    });

    if (!this.isApprover && !this.isAdmin) {
      this.accountsService.getSignedUser().subscribe((result: any) => {
        this.subDeptList.forEach((element) => {
          if (element.id == result.subDepartmentId)
            this.subDeptList = [element];
        });
        this.departmentList.forEach((element2) => {
          if (element2.id == result.departmentId)
            this.departmentList = [element2];
        });
      });

      this.granularityList = [
        { value: 1, label: "Department" },
        { value: 2, label: "Sub Department" },
      ];
    } else {
      this.granularityList = [
        { value: 0, label: "Total Company" },
        { value: 1, label: "Department" },
        { value: 2, label: "Sub Department" },
      ];
    }

    if (this.isRoleValid == false || this.companyPackage == "basic")
      this.router.navigate(["auth/404"]);
    this.companyNumberFormat =
      this.authService.getNumberFormat() == "noScale"
        ? ""
        : this.authService.getNumberFormat() == "thousands"
        ? "k"
        : this.authService.getNumberFormat() == "millions"
        ? "m"
        : "n/a";
    this.companyCurrency = this.authService.getCompanyCurrency();
  }

  ngOnInit() {
    this.narrativeService
      .listCostRequests /*Whole*/
      ()
      .subscribe((result) => {
        // gelmiş geçmiş tüm requestler
        this.costRequestList = result;
      });
    this.scenarioService.listAll().subscribe((result: any) => {
      this.scenarioList = result;
    });
    this.guidanceService.getGuidance().subscribe((result: any) => {
      this.guidanceIsStart = result.start;
      this.guidanceId = result.id;
      this.guidanceService
        .getPlanningRecord(result.id)
        .subscribe((result2: any) => {
          this.planningPeriodList = result2;
        });
      this.guidanceService.getFxRate(this.guidanceId).subscribe((data) => {
        this.guidanceFxRateList = data;
      });
    });
    this.guidanceService.listAllFxRates().subscribe((result: any) => {
      this.fxrateList = result;
    });
  }

  getFxRate(fxRateId) {
    if (fxRateId == 0) {
      return "Default Currency";
    }
    for (let index in this.fxrateList) {
      if (fxRateId == this.fxrateList[index].id) {
        return this.fxrateList[index].name;
      }
    }
  }

  isValid() {
    if (
      this.requestControl.valid == false ||
      this.categoryControl.valid == false ||
      this.granularityControl.valid == false ||
      this.currencyControl.valid == false
    ) {
      this.translate.get("reports.allrequired").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    } else if (
      this.requestControl.valid == true &&
      this.categoryControl.valid == true &&
      this.granularityControl.valid == true &&
      this.currencyControl.valid == true
    ) {
      if (this.granularity == 0) {
        this.run();
      } else if (this.granularity == 1) {
        if (this.depControl.valid == true) {
          this.run();
        } else {
          this.translate.get("reports.allrequired").subscribe((data: any) => {
            this.toastr.error(data);
          });
          return;
        }
      } else if (this.granularity == 2) {
        if (this.subControl.valid == true) {
          this.run();
        } else {
          this.translate.get("reports.allrequired").subscribe((data: any) => {
            this.toastr.error(data);
          });
          return;
        }
      }
    }
  }

  run() {
    this.isRun = false;

    this.reportData.chartLabels;
    this.barChartOptions = {};
    this.barChartData = [];

    let catId;
    if (this.granularity == 0) {
      catId = 0;
    } else if (this.granularity == 1) {
      catId = this.departmentId;
    } else if (this.granularity == 2) {
      catId = this.subDepartmentId;
    }

    this.narrativeService
      .costBridge(
        this.requestId,
        this.categoryId,
        this.granularity,
        catId,
        this.currency
      )
      .subscribe((result) => {
        let tempArr = [];
        var groupedData = result?.data.reduce(function (obj, x) {
          (obj[x["stack"]] = obj[x["stack"]] || []).push(x);
          return obj;
        }, {});

        let start = groupedData[1];
        let end = groupedData[Object.keys(groupedData).length];

        var formattedData = [...start];

        Object.keys(groupedData).forEach((key: any) => {
          if (key == 1 || key == Object.keys(groupedData).length) return;
          var imapct = groupedData[key];

          if (imapct[0]["data"] < 0) {
            let colorBar = imapct[1].isRed
              ? "red"
              : imapct[1].isWhite
              ? "white"
              : "green";

            imapct[0]["dataLabel"] = "hidden";
            imapct[0]["isWhite"] = false;
            imapct[0]["isRed"] = colorBar == "red" ? true : false;

            imapct[1]["dataLabel"] = imapct[1]["data"];
            imapct[1]["data"] = imapct[1]["data"] + imapct[0]["data"];

            imapct[1]["percentage"] =
              ((start[0]["data"] - imapct[1]["dataLabel"] - start[0]["data"]) /
                start[0]["data"]) *
              100;
          } else
            imapct[1]["percentage"] =
              ((start[0]["data"] - imapct[1]["data"] - start[0]["data"]) /
                start[0]["data"]) *
              100;

          if (imapct[1]["isRed"] && !imapct[1]["isWhite"])
            imapct[1]["percentage"] = Math.abs(imapct[1]["percentage"]) * -1;
          else if (!imapct[1]["isRed"] && !imapct[1]["isWhite"])
            imapct[1]["percentage"] = Math.abs(imapct[1]["percentage"]);

          formattedData.push(imapct[0]);
          formattedData.push(imapct[1]);
        });

        formattedData.push(end[0]);


        let numberDecimal = this.numberDecimal;
        let decimalFormat = this.decimalFormat;
        let numberFormat = this.numberFormat;

        formattedData.forEach((ele, index: number) => {
          let color;
          if (ele.isWhite == true) color = "white";
          else if (ele.isRed == true) color = "#E74C3C";
          else if (ele.isSolid == true) color = "#B2BABB";
          else if (
            ele.isRed == false &&
            ele.isWhite == false &&
            ele.isSolid == false
          )
            color = "#27AE60";

          let value = ele.data.toFixed(2);

          tempArr.push({
            label: `${
              ele.isWhite == true || ele.dataLabel == "hidden"
                ? "remove"
                : ele.label
            }`,
            data: [value],
            datalabels: {
              formatter: function (value, context) {
                var showPercentage = ele.isSolid ? false : true
                if ("dataLabel" in ele) value = ele.dataLabel;
                if (showPercentage) {
                      return (
                        numberDecimal.transform(
                          Number(value),
                          decimalFormat,
                          numberFormat
                        ) +
                        "\n" +
                        "\n" +
                        "%" +
                        ele.percentage.toFixed(2)
                      );
                    } else {
                      return numberDecimal.transform(
                        Number(value),
                        decimalFormat,
                        numberFormat
                      );
                    }
              },
            },
            waterfall: {
              dummyStack: ele.isWhite == true ? true : false,
            },
            percentage: `${ele.percentage}`,
            backgroundColor: `${color}`,
            hoverBackgroundColor: `${color}`,
            stack: `stack ${ele.stack}`,
            showPercentage: ele.isSolid ? false : true,
          });
        });

        this.barChartOptions = {
          scaleShowVerticalLines: false,
          responsive: true,
          barThickness: 4,
          tooltips: {
            enabled: false,
            mode: "single",
            callbacks: {
              label: function (tooltipItems, data) {
                if (data.datasets[tooltipItems.datasetIndex].label != "remove")
                  return data.datasets[tooltipItems.datasetIndex].label;
                else return false;
              },
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {},
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
          },
          title: {
            display: false,
            text: "",
            position: "bottom",
          },
          plugins: {
            datalabels: {
              display: function (context) {
                if (context.dataset.label == "remove") return false;
                else return true;
              },
              // formatter: function (value, context) {
              //   if (context.dataset.showPercentage) {
              //     return (
              //       numberDecimal.transform(
              //         Number(value),
              //         decimalFormat,
              //         numberFormat
              //       ) +
              //       "\n" +
              //       "\n" +
              //       "%" +
              //       context.dataset.percentage
              //     );
              //   } else {
              //     return numberDecimal.transform(
              //       Number(value),
              //       decimalFormat,
              //       numberFormat
              //     );
              //   }
              // },
              color: function (context) {
                return "black";
              },
            },
            plugins: {
              waterFallPlugin: {
                stepLines: {
                  enabled: true,
                  startColorStop: 0,
                  endColorStop: 0.6,
                  startColor: "rgba(0, 0, 0, 0.55)",
                  endColor: "rgba(0, 0, 0, 0)",
                  diagonalStepLines: true,
                },
              },
            },
          },
          legend: {
            labels: {
              filter: function (item, chart) {
                return !item.text.includes("remove");
              },
            },
            position: "bottom",
          },
          // layout: {
          //   padding: {
          //     top: 30,
          //   },
          // },
          filter: function (legendItem, chartData) {
            var currentDataset = chartData[legendItem.datasetIndex];
            return !currentDataset.dummyStack;
          },
        };

        this.barChartData = [...tempArr];
        this.isRun = true;
      });
  }

  take() {
    if (this.isRun != true) {
      this.translate.get("reports.warning1").subscribe((data: any) => {
        this.toastr.error(data);
      });
      return;
    }
    this.captureService
      .getImage(this.screen.nativeElement, true, 400)
      .pipe(
        tap((img) => {
          this.img = img;
        })
      )
      .subscribe();
  }

  reset() {
    this.img = "";
  }

  getRequest(id: number) {
    if (id != null) {
      for (let index in this.costRequestList) {
        if (id == this.costRequestList[index].id) {
          return (
            this.costRequestList[index].firstPlanningCycleName +
            " " +
            this.costRequestList[index].firstYear +
            " " +
            "vs" +
            " " +
            this.costRequestList[index].secondPlanningCycleName +
            " " +
            this.costRequestList[index].secondYear
          );
        }
      }
    }
  }

  getCategory() {
    if (this.granularity == 1) {
      for (let index in this.departmentList) {
        if (this.departmentId == this.departmentList[index].id) {
          return this.departmentList[index].name;
        }
      }
    } else if (this.granularity == 2) {
      for (let index in this.subDepartmentList) {
        if (this.subDepartmentId == this.subDepartmentList[index].id) {
          return this.subDepartmentList[index].name;
        }
      }
    }
  }

  save() {
    var array = this.img.split(",");
    this.reportScreenshot.image = array[1];
    this.reportScreenshot.guidanceId = this.guidanceId;
    this.reportScreenshot.name = `${this.getRequest(this.requestId)} / ${
      this.granularity == 0
        ? this.granularityList[this.granularity].label
        : this.getCategory()
    } / ${this.currency}`;
    this.reportScreenshot.reportName = "Cost Bridge";

    this.reportScreenshotService
      .addReportScreenshot(this.reportScreenshot)
      .subscribe((backendResult) => {
        if (backendResult.isSuccess) {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.success(data);
            });
        } else {
          this.translate
            .get(`messages.${backendResult.messageId}`)
            .subscribe((data: any) => {
              this.toastr.error(data);
            });
        }
      });
  }
}
