import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ConfirmationDialogComponent } from "@planard/confirm/confirmation-dialog.component";
import { MatSort, Sort } from "@angular/material/sort";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Department } from "@planard/@core/entities/department";
import { SubDepartment } from "@planard/@core/entities/subDepartment";
import { Products } from "@planard/@core/entities/product";
import { ProductsService } from "@planard/@core/backend/service/products.service";
import { RoleService } from "@planard/@core/backend/service/role.service";
import { Role } from "@planard/@core/entities/role";
import { CostType } from "@planard/@core/entities/costType";
import { CostService } from "@planard/@core/backend/service/cost.service";
import { User } from "@planard/forms/autocomplete/autocomplete.component";
import { Users } from "../../../@core/entities/user";
import { AccountsService } from "../../../@core/backend/service/accounts.service";
import { UsersAddComponent } from "./add/users-add.component";
import { MatSelect } from "@angular/material/select";
import { Router } from "@angular/router";
import { AuthService } from "@planard/auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { UsersUpdateComponent } from "./update/users-update.component";
import { removeSpaces } from "@planard/common/formValidator";
import { environment } from "environments/environment";

@Component({
  templateUrl: "./users-new.component.html",
  styles: [
    `
      .toRightReverse {
        flex: 0 0 5%;
      }
      .toRight {
        flex: 0 0 7%;
      }
      #main-div {
      }
      .div {
        display: inline-block;
      }
      ::ng-deep .mat-option:first-child .mat-pseudo-checkbox {
        display: initial;
      }
    `,
  ],
})
export class UsersNewComponent implements OnInit {
  displayedColumns: string[] = [
    "name",
    "email",
    "approverUserId",
    "departmentId",
    "subDepartmentId",
    "selectedRoles",
    "selectedCentralCosts",
    "selectedProducts",
    "link",
    "actions",
  ];
  dataSource = new MatTableDataSource<Users>();
  controls: any[];

  departmentList: Department[] = [];
  subdepartmentList: SubDepartment[] = [];
  subdepartmentDeptList: SubDepartment[] = [];
  productList: Products[] = [];
  roleList: Role[] = [];
  centralCostList: CostType[] = [];
  costTypeList: CostType[] = [];
  userList: Users[] = [];
  user: User;
  adminCount: number = 0;
  adminId: string;
  allProducts: boolean;
  isRoleValid: boolean;
  companyPackage: string;
  usercount: number;
  companyUserCount: number;

  redirect_uri = environment.callback;
  auth_uri = environment.authority;

  basicRoles: string[] = [
    "Admin",
    "Price Planner",
    "Sales Planner",
    "Cost Planner",
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private roleService: RoleService,
    private costtypeService: CostService,
    public productsService: ProductsService,
    private accountsService: AccountsService,
    public authService: AuthService,
    public translate: TranslateService,
    private router: Router
  ) {
    this.isRoleValid = this.authService.isAdmin();
    this.companyPackage = this.authService.getCompanyPackage();
    this.companyUserCount = this.authService.getCompanyUserCount();
    this.displayedColumns =
      this.companyPackage == "premium"
        ? [
            "name",
            "email",
            "approverUserId",
            "departmentId",
            "subDepartmentId",
            "selectedRoles",
            "selectedCentralCosts",
            "selectedProducts",
            "link",
            "actions",
          ]
        : [
            "name",
            "email",
            "selectedRoles",
            "selectedProducts",
            "link",
            "actions",
          ];
    if (this.isRoleValid == false) this.router.navigate(["auth/404"]);
  }

  ngOnInit() {
    this.productsService.listAll().subscribe((resultList) => {
      this.productList = resultList;
    });
    this.accountsService.listDepartments().subscribe((resultList) => {
      this.departmentList = resultList;
    });
    this.accountsService.listSubDepartments().subscribe((resultList) => {
      this.subdepartmentList = resultList;
      this.subdepartmentDeptList = [...resultList];
    });
    this.roleService.list().subscribe((resultList) => {
      this.roleList = [];
      if (this.companyPackage != "basic")
        this.roleList = resultList.filter((r) => r.name != "Super Admin");
      else if (this.companyPackage == "basic")
        this.roleList = resultList.filter(
          (r) => r.name != "Super Admin" && this.basicRoles.includes(r.name)
        );
    });
    this.costtypeService.listAll().subscribe((result: any) => {
      this.costTypeList = result;
      this.centralCostList = [...result];
      this.setCentral();
    });
    this.getData();
  }

  selectAll(index, select: MatSelect) {
    var control = this.getControl(index, "selectedProducts");
    let selectedIds = [];
    select.options.forEach((element) => {
      if (element.value != null) {
        selectedIds.push(element.value);
      }
    });
    control.setValue(selectedIds);
    this.allProducts = true;
  }

  deselectOne(id: number, select: MatSelect) {
    var control = this.getControl(id, "selectedProducts");
    if (this.isAll(control) == true) {
      this.allProducts = false;
      let selectedIds = [];
      select.options.forEach((element) => {
        if (element.value != null && id != element.value.id) {
          selectedIds.push(element.value);
        }
      });
      control.setValue(selectedIds);
    }
  }

  isAll(selectedProducts) {
    if (selectedProducts.length == this.productList.length) return true;
    return false;
  }

  deselectAll(id) {
    var control = this.getControl(id, "selectedProducts");
    let selectedIds = [];
    control.setValue(selectedIds);
  }

  selectOne(index, id: number) {
    if (this.allProducts == true) {
      var control = this.getControl(index, "selectedProducts");
      this.allProducts = false;
      let selectedId = [id];
      control.setValue(selectedId);
    }
  }

  getData() {
    this.accountsService.listUsers().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);
      this.usercount = data.length;
      this.userList = [...data];
      this.userList = this.userList.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
          typeof data[sortHeaderId] === "string"
            ? data[sortHeaderId].toLocaleLowerCase()
            : data[sortHeaderId];
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: "name", direction: "asc" };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
      }

      this.controls = data.map((entity) => {
        return {
          data: entity,
          formGroup: new FormGroup(
            {
              name: new FormControl(entity.name, [
                Validators.required,
                removeSpaces,
              ]),
              email: new FormControl(entity.email, [
                Validators.required,
                Validators.email,
                removeSpaces,
              ]),
              approverUserId: new FormControl(entity.approverUserId),
              departmentId: new FormControl(entity.departmentId, [
                Validators.required,
              ]),
              subDepartmentId: new FormControl(entity.subDepartmentId),
              selectedProducts: new FormControl(entity.selectedProducts),
              selectedRoles: new FormControl(entity.selectedRoles, [
                Validators.required,
              ]),
              selectedCentralCosts: new FormControl(
                entity.selectedCentralCosts
              ),
            },
            { updateOn: "change" }
          ),
        };
      });

      this.adminCount = 0;
      this.userList.forEach((user) => {
        user.rolesStr.split(", ").forEach((role) => {
          if (role == "Admin") {
            this.adminCount++;
            this.adminId = user.id.toString();
          }
        });
      });
    });
  }

  getRolesList(roles: string) {
    if (!roles) return "-";
    var roleList = roles.split(", ");
    var translatedRoles: string[] = [];
    roleList.forEach((role) => {
      this.translate.get(`processRoles.${role}`).subscribe((data: any) => {
        translatedRoles.push(data);
      });
    });
    return translatedRoles.join(", ");
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    var entity = this.getElement(index);
    entity[field] = control.value;

    let deptvalid = true;
    let controlList = ["departmentId"];
    controlList.forEach((element) => {
      if (entity[element] == null) deptvalid = false;
    });

    if (control.valid) {
      if (!deptvalid) {
        this.translate.get(`users.addDept`).subscribe((data: any) => {
          this.toastrService.error(data);
        });
        return;
      }

      var isAdminRoleSelected = false;
      this.roleList.forEach((r) => {
        if (r.name == "Admin" && entity.selectedRoles.includes(r.id)) {
          isAdminRoleSelected = true;
        }
      });

      if (
        !isAdminRoleSelected &&
        this.adminCount == 1 &&
        entity.id == this.adminId
      ) {
        this.translate.get(`users.oneadminleft`).subscribe((data: any) => {
          this.toastrService.error(data);
        });
        return;
      }

      if (this.getSupportFunction(entity.selectedRoles)) {
        if (
          entity.subDepartmentId == null ||
          entity.subDepartmentId == undefined
        ) {
          this.translate.get(`users.spf`).subscribe((data: any) => {
            this.toastrService.error(data);
          });
          return;
        }
      }

      this.accountsService.updateUser(entity).subscribe((result) => {
        if (result.isSuccess) {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.success(data);
            });
          this.getData();
        } else {
          this.translate
            .get(`messages.${result.messageId}`)
            .subscribe((data: any) => {
              this.toastrService.error(data);
            });
          this.getData();
        }
      });
    } else {
      this.translate.get("common.formNotValid").subscribe((data: any) => {
        this.toastrService.error(data);
      });
      this.ngOnInit();
    }
  }

  getControl(index, fieldName) {
    return this.controls
      .find((group) => group.data.id == index)
      ["formGroup"].get(fieldName) as FormControl;
  }

  getElement(index) {
    return this.controls.find((group) => group.data.id == index)["data"];
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  setCentral() {
    this.centralCostList.length = 0;
    for (let ct of Object.keys(this.costTypeList)) {
      if (this.costTypeList[ct].costType === "CENTRAL") {
        this.centralCostList.push(this.costTypeList[ct]);
      }
    }
  }

  onAdd() {
    if (this.usercount < this.companyUserCount) {
      const dialogRef = this.dialog.open(UsersAddComponent, {
        width: "500px",
      });
      dialogRef.afterClosed().subscribe(() => {
        this.getData();
      });
    } else {
      this.translate.get("users.max").subscribe((data: any) => {
        this.toastrService.error(data);
      });
    }
  }

  onDelete(row: any) {
    if (row.isContact == true) {
      this.translate.get("delete.userContact").subscribe((data: any) => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: "350px",
          data: data,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const dialogRef = this.dialog.open(UsersUpdateComponent, {
              width: "500px",
              data: {
                user: row,
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.ngOnInit();
              }
            });
          }
        });
      });
    } else {
      this.translate.get("delete.user").subscribe((data: any) => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: "350px",
          data: data,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.accountsService.deleteUser(row.id).subscribe((result) => {
              const processResult = result;
              if (processResult.isSuccess) {
                this.translate
                  .get(`messages.${result.messageId}`)
                  .subscribe((data: any) => {
                    this.toastrService.success(data);
                  });
                this.getData();
              } else {
                this.translate
                  .get(`messages.${result.messageId}`)
                  .subscribe((data: any) => {
                    this.toastrService.error(data);
                  });
                this.getData();
              }
            });
          }
        });
      });
    }
  }

  subDepartmentSet(departmentId: any) {
    this.subdepartmentDeptList = [];
    for (let sd of Object.keys(this.subdepartmentList)) {
      if (departmentId == this.subdepartmentList[sd].departmentId) {
        this.subdepartmentDeptList.push(this.subdepartmentList[sd]);
      }
    }
  }

  getSupportFunction(roles: any) {
    for (let rl of roles) {
      for (let role of this.roleList) {
        if (
          rl === role.id &&
          (role.name == "Cost Planner" || role.name == "Cost Approver")
        ) {
          return true;
        }
      }
    }
  }

  getCentral(roles: any) {
    for (let rl of roles) {
      for (let role of this.roleList) {
        if (rl === role.id && role.name == "Central Cost Planner") {
          return true;
        }
      }
    }
  }

  getProduct(roles: any) {
    for (let rl of roles) {
      for (let role of this.roleList) {
        if (
          rl === role.id &&
          (role.name == "Sales Planner" ||
            role.name == "Sales Approver" ||
            role.name == "Price Planner" ||
            role.name == "Admin")
        ) {
          return true;
        }
      }
    }
  }
}
